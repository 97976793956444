@import 'libs/assets/theme/mixins.scss';

.image-wrapper {
  width: 100%;
  height: 100%;

  @include below-sm {
    margin-top: 0.5rem;
    margin-left: 0;
  }

  > img {
    width: 100%;
    object-fit: contain;
  }
}
