@import 'libs/assets/theme/coreVariablesV2.scss';

.button {
  font-family: inherit;
  font-size: 0.8125rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;

  &--underlined {
    text-decoration: underline;
    color: #{$primaryColor};
  }

  &--no-padding-left {
    padding-left: 0;
    padding-right: 0;
  }

  &--no-padding-top {
    padding-top: 0;
    padding-bottom: 0;
  }
}
