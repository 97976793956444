@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.stretch {
  width: 100%;
}

.button {
  display: grid;
  grid-auto-flow: column;
  color: $darkGray;
}

.xsGap {
  grid-gap: 0.5rem;
}

.xxsGap {
  grid-gap: 0.25rem;
}

.contained,
.outlined {
  border-radius: 0.25rem;
}

.xxsPadding {
  padding: 0.375rem 0.5rem;
}

.xsPadding {
  padding: 0.375rem 0.75rem;
}

.mdPadding {
  padding: 1rem 0.5rem;
}

.xsVerticalPadding {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.left {
  justify-self: flex-start;
}

.center {
  justify-self: center;
}

.right {
  justify-self: flex-end;
}

.contained {
  color: $white;
  &.red100pct {
    background-color: $red100pct;
    &.disabled {
      background-color: $darkGray;
    }
  }

  &.darkGray {
    background-color: $darkGray;
  }

  &.lightGray {
    background-color: $lightGray;
  }
}

.outlined {
  border: 0.0625rem solid;

  &.red100pct {
    border-color: $red100pct;
  }

  &.darkGray {
    border-color: $darkGray;
  }

  &.lightGray {
    @include actionLightGrayTheme;
  }
}

.underline {
  border-bottom: 0.0625rem solid;
}

.bgColor {
  &--white {
    background-color: $white;
  }
}
