@import 'libs/assets/theme/coreVariablesV2.scss';

.labeledCheckbox {
  align-items: center;
  color: $darkGray;
  display: flex;
  gap: 0.5rem;
  :global .MuiCheckbox-root {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
}

.infoLink {
  color: $darkGray;
  text-decoration: underline;
  margin-left: 0.3rem;
}
