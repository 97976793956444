.promotion-wrapper {
  margin-top: '1rem';
  margin-right: '1rem';
}
.auth-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  .explainer {
    width: auto;
    flex: auto;
  }

  .btn-wrapper {
    flex: 1;
  }
}
