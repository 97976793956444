@import '@react-page/editor/lib/index.css';
@import '@react-page/plugins-slate/lib/index.css';
@import '@react-page/plugins-spacer/lib/index.css';
@import '@react-page/plugins-video/lib/index.css';
@import 'libs/assets/theme/mixins.scss';

.react-page-row {
  .react-page-cell {
    padding: 0 0.5rem;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.react-page-cell-inner-leaf {
  padding: 0.75rem 0;
}

.react-page-cell-inner-leaf ul,
.react-page-cell-inner-leaf ol {
  padding-inline-start: 1rem;
}

.react-page-cell-inner-leaf ul > li {
  margin: 2rem 0;
}

.react-page-cell-inner-leaf ol > li {
  margin: 1rem 0;
}
