@import 'libs/assets/theme/coreVariablesV2.scss';

.common-font {
  font-family: 'Lato', sans-serif;
  color: #3f3f3f;
}

.code-wrapper {
  font-family: 'firacode', sans-serif;
  font-size: 13px;
  white-space: pre-wrap;
}

.bold-text-wrapper {
  font-weight: 700;
}

.blockquote-container {
  margin: 2.5rem 0;
  padding: 1rem;
  background: $lightGray5K;
  border-radius: 0.25rem;
}

.heading-wrapper {
  margin-bottom: 0.5rem;
}

.anchor-wrapper {
  margin-bottom: 0.5rem;
  padding: 1.125rem 0 0.75rem;
  text-decoration: underline;
  cursor: pointer;
}
.red-link {
  color: $red100pct;
}

.code-block-wrapper {
  margin: 2.5rem 0;
  > pre {
    font-family: 'inherit', sans-serif;
  }
}
.pre-block-wrapper {
  margin: 0;
}
