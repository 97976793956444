@import 'libs/assets/theme/coreVariablesV2.scss';

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.btnCollapsible {
  color: $darkGray;
}

.title,
.btnCollapsible {
  cursor: pointer;
}

.collapsibleTitle:before {
  content: counters(item, '.', decimal-leading-zero) '. ';
  counter-increment: item;
}

.titleUnderline {
  background-color: $lightGray;
  height: 1px;
  margin: 1rem 0;
}

.mainWrapper {
  margin-top: 2.5rem;
}

.mainWrapper ol li {
  display: table-row;
  color: $darkGray;
  line-height: 1.25rem;
  &:first-child {
    margin-top: 0;
  }
}

.contentWrapper {
  margin-top: 1rem;
  font-size: 0.875rem;
}

.contentWrapper p:first-child {
  margin-top: 1rem;
}

.mainWrapper ol {
  padding-inline-start: 0;
  border-collapse: separate;
  border-spacing: 0 1rem;
  margin-top: -1rem;
}

.mainWrapper ol li:before {
  content: counters(item, '.', decimal-leading-zero) ' ';
  counter-increment: item;
  text-align: left;
  width: 3.25rem;
  display: table-cell;
}
