@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations';

.topSectionBackground {
  @include grayFullWidthBg;
  z-index: 0;
}

.sectionWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @include dropShadow2;
  background: #fff;
  border-radius: 0.25rem;
  padding: 3.375rem 3.75rem;
  margin: 1.25rem 0;

  &.mobileWrapper {
    padding: 1.375rem 1.75rem;
  }
}

.headline {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.75rem;
}

.submitButton {
  max-width: 37.5rem;
  margin-top: 2rem;
  flex-grow: 1;
}

.radio {
  :global .MuiRadio-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  :global .MuiTypography-root {
    font-weight: 700;
  }
}
