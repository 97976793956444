@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.promotion-checkout {
  &-wrapper {
    margin-top: 1rem;
    @include lg {
      margin-top: 1.5rem;
    }
    display: flex;
    padding: 1rem;
    width: 100%;
    color: $darkGray;
    background-color: $white;
    &-shadow {
      @include dropShadow1;
    }
  }
  &-no-padding {
    padding: 0;
  }
  &-promotion-data {
    width: 100%;
  }
  &-header {
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-text-block {
      display: flex;
      align-items: center;
    }
    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  &-title {
    margin-right: 1rem;
    &-expired {
      color: $darkGray55K;
    }
  }
  &-date {
    color: $darkGray55K;
  }
  &-benefit-image {
    margin-left: 1rem;
    width: 5.875rem;
    height: 5.25rem;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
