@import 'libs/assets/theme/coreVariablesV2.scss';

.progress-status-wrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.progress-status-text {
  padding-left: 0.5rem;
}

.progress-status-reward-text {
  color: $green;
}

.progress-status-sum-text {
  color: $orange;
}

.progress-status-delivery-text {
  color: $darkGray55K;
}
