@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2.scss';

.top-section-background {
  @include grayFullWidthBg;
  z-index: 0;
}
.cardWrapper {
  position: relative;
  width: 16rem;
  padding: 5px;
}

.right-adornment-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.right-adornment-text {
  margin-left: 0.5rem;
}

.section-wrapper {
  margin: 0;
}
.top-section-wrapper {
  padding: 1.5rem 0;
  position: relative;
}
