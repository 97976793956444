@import 'libs/assets/theme/mixins.scss';

.root div {
  & > :global(.react-page-row) {
    & > :global(.react-page-cell) {
      padding: 0 0.75rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  @include below-sm {
    & > :global(.react-page-row) {
      flex-direction: column-reverse;
      & > :global(.react-page-cell) {
        padding: 0;
      }
    }
  }
  :global(.obeta-custom-image) {
    padding: 0;
  }
}
